import Header from './Header';
import Footer from './Footer';

const ContactsPage = () => {


  return (
    <>
    <Header />
    <div className='bg-blue custom-bg w-full'>
      <div className='container max-w-6xl mx-auto md:py-16 py-10 lg:px-0 px-5'>
          <h1 className='text-white lg:text-5xl text-3xl font-bold'>Контакты</h1>
      </div>
    </div>
    <div className='container max-w-6xl mx-auto py-10 lg:px-0 px-5 md:flex md:flex-row flex-col'>
      <div className='md:w-1/2 w-full flex flex-col justify-center'>
        <div className='lg:text-2xl md:text-2xl text-xl font-bold'>
          Офис
        </div>
        <div className='text-xl mt-3'>
          г. Рязань, ул. Грибоедова, д.65.
        </div>
        <div className='text-xl mt-1'>
          manager@orbita62.ru
        </div>
        <div className='text-xl mt-1'>
          +7 (4912) 28-13-15
        </div>
        <div className='lg:text-2xl md:text-2xl text-xl font-bold mt-10'>
          Лагерь
        </div>
        <div className='text-xl mt-3'>
          Рязанская область, Спасский район, Заречинское сельское поселение, посёлок Спиртзаводской
        </div>
        <div className='lg:text-2xl md:text-2xl text-xl font-bold mt-14'>
          Реквизиты
        </div>
        <div className='text-xl mt-3'>
          РОО ОГО ВФСО «Динамо»<br />
          ИНН 6231028396 / ОГРНИП 1026200001024
        </div>
        <a href="https://vk.com/lagerorbita" target="_blank" rel='noreferrer' className='text-xl mt-10 color-primary font-bold underline'>Группа ВК</a>
        <a href="https://dynamo-ryazan.ru/" target="_blank" rel='noreferrer' className='text-xl mt-1 color-primary font-bold underline'>Динамо</a>
      </div>
      <div className='md:w-1/2 w-full flex flex-col justify-center md:mt-0 mt-10'>
        <iframe title='Карта' src="https://yandex.ru/map-widget/v1/?um=constructor%3Affafc6a42eef6f8875658957da50ed13549f1590eb7ea1c50c3cdda96cf09f74&amp;source=constructor" width="100%" height="600" frameBorder="0"></iframe>
      </div>
    </div>
    <Footer />
    </>
  );


};

export default ContactsPage;
