import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import PurchasePage from './components/PurchasePage';
import ContactsPage from './components/ContactsPage';
import PrivacyPage from './components/Privacy';
import CookieConsent from "react-cookie-consent";
import PurchasePageLimited from './components/PurchasePageLimited';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import PaymentFailPage from './components/PaymentFailPage';
import PageNotFound from './components/404';

function App() {
  
  return (
    
    <Router>
      <CookieConsent
        style={{ background: "#fff", color: "black", fontSize: "14px", borderTop: "1px solid black" }}
        buttonStyle={{ color: "#fff", background: "black"}}
        location='bottom'
        expires={150}
        buttonText="Я принимаю"
      >
        Этот сайт использует файлы cookie для улучшения пользовательского опыта.
      </CookieConsent>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/purchase" element={<PurchasePage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/sport" element={<PurchasePageLimited />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
        <Route path="/payment-fail" element={<PaymentFailPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
    
  );
}

export default App;
