import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';


const PurchasePage = () => {
  const [parentData, setParentData] = useState({
    parent_name: '',
    parent_passport_serial: '',
    parent_passport_number: '',
    parent_passport_issue_date: '',
    parent_passport_issued_by: '',
    parent_passport_department_code: '',
    parent_birth_date: '',
    parent_address: '',
    parent_phone: '',
    parent_email: ''
  });
  const [children, setChildren] = useState([{ docType: 'Паспорт', campId: null, campCost: 0, childName: '', childBirthDate: '', childDocumentSerial: '', childDocumentNumber: '', childDocumentIssueDate: '', childDocumentIssuedBy: '', childDocumentDepartmentCode: '', childAdditionalInfo: '' }]);
  const [camps, setCamps] = useState([]);
  const apiBaseURL = "https://orbita62.ru/api";

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for disabling the button

  useEffect(() => {
    axios.get(`${apiBaseURL}/api/camps-buy`)
      .then(response => {
        setCamps(response.data);
      })
      .catch(error => console.error('Ошибка при загрузке данных смен', error));
  }, []);

  const handleParentInputChange = (event, key) => {
    setParentData({ ...parentData, [key]: event.target.value });
  };

  const handleChildInputChange = (index, key, value) => {
    const updatedChildren = [...children];
    updatedChildren[index] = { ...updatedChildren[index], [key]: value };
    setChildren(updatedChildren);
  };

  const handleCampChange = (index, campId) => {
    const selectedCamp = camps.find(camp => camp.camp_id === parseInt(campId));
    console.log(campId);
    const updatedChildren = [...children];
    const updatedChild = {
      ...updatedChildren[index],
      campId: parseInt(campId), 
      campCost: selectedCamp ? selectedCamp.camp_cost : 0
    };
    updatedChildren[index] = updatedChild;
    setChildren(updatedChildren);
  };
  

  const handleDocTypeChange = (index, docType) => {
    const updatedChildren = [...children];
    updatedChildren[index] = {
      ...updatedChildren[index],
      docType: docType,
      childDocumentSerial: '', // Очищаем серию документа
      childDocumentNumber: '', // Очищаем номер документа
      childDocumentIssueDate: '', // Очищаем дату выдачи
      childDocumentIssuedBy: '', // Очищаем кем выдан
      childDocumentDepartmentCode: '' // Очищаем код подразделения
    };
    setChildren(updatedChildren);
  };
  

  const getTotalCost = () => {
    return children.reduce((total, child) => total + child.campCost, 0);
  };

  const addChild = () => {
    setChildren([...children, { docType: 'Паспорт', campId: null, campCost: 0 }]);
  };

  const removeChild = index => {
    if (children.length > 1) {
      setChildren(children.filter((_, childIndex) => childIndex !== index));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    const orderDetails = children.map(child => ({
      camp_id: child.campId,
      child_name: child.childName,
      child_document_type: child.docType,
      child_document_serial: child.childDocumentSerial,
      child_document_number: child.childDocumentNumber,
      child_document_issue_date: child.childDocumentIssueDate,
      child_document_issued_by: child.childDocumentIssuedBy,
      child_document_department_code: child.childDocumentDepartmentCode,
      child_birth_date: child.childBirthDate,
      child_additional_info: child.childAdditionalInfo,
      promocode_id: null  // Пример, если у вас есть промокоды
    }));

    const requestData = {
      order: {
        payment_type: 'Эквайринг',
      },
      parent: parentData,
      order_details: orderDetails
    };



    axios.post(`${apiBaseURL}/api/order`, requestData)
    .then(response => {
      // Проверка наличия URL для перенаправления в ответе
      if (response.data && response.data.paymentUrl) {
        // Перенаправление пользователя на страницу оплаты
        window.location.href = response.data.paymentUrl;
      } else {
        // Обработка случая, когда URL для перенаправления отсутствует в ответе
        console.error('URL для оплаты не получен');
      }
    })
    .catch(error => {
      console.error(error);
    });
  };



  

  return (
    <>
    
      <Header />
      <div className='bg-blue custom-bg w-full'>
        <div className='container max-w-6xl mx-auto md:py-16 py-10 lg:px-0 px-5'>
          <h1 className='text-white lg:text-5xl text-3xl font-bold'>Покупка путевки</h1>
        </div>
      </div>
  
      <div className='container max-w-6xl mx-auto py-10 lg:px-0 px-5'>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-10" role="alert">
          <p className="font-bold">Внимание!</p>
          <p>После оплаты, чтобы получить путевку надо будет явиться в офис по адресу г. Рязань, ул. Грибоедова, 65.</p>
        </div>
        <form onSubmit={handleSubmit} className="mb-4">
          {/* Форма для сбора данных родителя */}
          <div className="mb-4">
            <p>ФИО родителя (опекуна)</p>
            <input 
              type="text" 
              required 
              placeholder="Иванов Иван Иванович" 
              className="w-full p-2 border border-gray-300 rounded-none" 
              value={parentData.parent_name} 
              onChange={(e) => handleParentInputChange(e, 'parent_name')} 
            />
          </div>
  
          <div className="md:flex md:flex-wrap -mx-2">
            <div className="md:w-1/3 px-2 mb-2">
              <p>Серия паспорта</p>
              <input 
                type="text" 
                required 
                placeholder="1234" 
                className="w-full p-2 border border-gray-300 rounded-none" 
                value={parentData.parent_passport_serial} 
                onChange={(e) => handleParentInputChange(e, 'parent_passport_serial')} 
              />
            </div>
            <div className="md:w-1/3 px-2 mb-2">
              <p>Номер паспорта</p>
              <input 
                type="text" 
                required 
                placeholder="567890" 
                className="w-full p-2 border border-gray-300 rounded-none" 
                value={parentData.parent_passport_number} 
                onChange={(e) => handleParentInputChange(e, 'parent_passport_number')} 
              />
            </div>
            <div className="md:w-1/3 px-2 mb-2">
              <p>Дата выдачи паспорта</p>
              <input 
                type="date" 
                required 
                className="w-full p-2 border border-gray-300 rounded-none" 
                value={parentData.parent_passport_issue_date} 
                onChange={(e) => handleParentInputChange(e, 'parent_passport_issue_date')} 
              />
            </div>
          </div>
  
          <div className="md:flex md:flex-wrap -mx-2">
            <div className="md:w-2/3 px-2 mb-2">
              <p>Кем выдан паспорт</p>
              <input 
                type="text" 
                required 
                placeholder="ОВД г. Москвы" 
                className="w-full p-2 border border-gray-300 rounded-none" 
                value={parentData.parent_passport_issued_by} 
                onChange={(e) => handleParentInputChange(e, 'parent_passport_issued_by')} 
              />
            </div>
            <div className="md:w-1/3 px-2 mb-2">
              <p>Код подразделения</p>
              <input 
                type="text" 
                required 
                placeholder="123-456" 
                className="w-full p-2 border border-gray-300 rounded-none" 
                value={parentData.parent_passport_department_code} 
                onChange={(e) => handleParentInputChange(e, 'parent_passport_department_code')} 
              />
            </div>
          </div>
  
          <div className="mb-4">
            <p>Дата рождения</p>
            <input 
              type="date" 
              required 
              className="w-full p-2 border border-gray-300 rounded-none" 
              value={parentData.parent_birth_date} 
              onChange={(e) => handleParentInputChange(e, 'parent_birth_date')} 
            />
          </div>
  
          <div className="mb-4">
            <p>Адрес прописки</p>
            <input 
              type="text" 
              required 
              placeholder="г. Москва, ул. Пушкина, д. 1" 
              className="w-full p-2 border border-gray-300 rounded-none" 
              value={parentData.parent_address} 
              onChange={(e) => handleParentInputChange(e, 'parent_address')} 
            />
          </div>
  
          <div className="mb-4">
            <p>Контактный телефон</p>
            <input 
              type="tel" 
              required 
              placeholder="+7 (900) 123-45-67" 
              className="w-full p-2 border border-gray-300 rounded-none" 
              value={parentData.parent_phone} 
              onChange={(e) => handleParentInputChange(e, 'parent_phone')} 
            />
          </div>
  
          <div className="mb-4">
            <p>Email</p>
            <input 
              type="email" 
              required 
              placeholder="example@mail.com" 
              className="w-full p-2 border border-gray-300 rounded-none" 
              value={parentData.parent_email} 
              onChange={(e) => handleParentInputChange(e, 'parent_email')} 
            />
          </div>
  
          {/* Форма для сбора данных о детях */}
          {children.map((child, index) => (
            <div key={index} className="mb-6 p-4 border border-gray-200 rounded-lg">
              <h2 className="text-lg font-semibold mb-2">Путевка №{index + 1}</h2>

              <div className="mb-4">
                <p>Смена</p>
                <select 
                  className="w-full p-2 border border-gray-300 rounded-none"
                  value={child.campId}
                  onChange={(e) => handleCampChange(index, e.target.value)}
                  required={true}
                >
                  <option value="">Выберите смену</option>
                  {camps.map(camp => (
                    <option key={camp.camp_id} value={camp.camp_id}>
                      {camp.camp_title + ' (' + camp.camp_start_date + ' - ' + camp.camp_end_date + ')'}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <p>ФИО ребенка</p>
                <input 
                  type="text" 
                  required 
                  placeholder="Иванов Иван Иванович" 
                  className="w-full p-2 border border-gray-300 rounded-none" 
                  value={child.childName} 
                  onChange={(e) => handleChildInputChange(index, 'childName', e.target.value)} 
                />
              </div>

              <div className="mb-4">
                <p>Дата рождения ребенка</p>
                <input 
                  type="date" 
                  required 
                  className="w-full p-2 border border-gray-300 rounded-none" 
                  value={child.childBirthDate} 
                  onChange={(e) => handleChildInputChange(index, 'childBirthDate', e.target.value)} 
                />
              </div>

              <div className="mb-4">
                <p>Тип документа</p>
                <select 
                  className="w-full p-2 border border-gray-300 rounded-none"
                  value={child.docType}
                  onChange={(e) => handleDocTypeChange(index, e.target.value)}
                >
                  <option value="Паспорт">Паспорт</option>
                  <option value="Свидетельство о рождении">Свидетельство о рождении</option>
                </select>
              </div>

              {/* Дополнительные поля в зависимости от типа документа */}
              {/* Поля для паспорта или свидетельства о рождении, условное отображение */}
              {child.docType === 'Паспорт' && (
                <>
                  <div className="md:flex md:flex-wrap -mx-2">
                    <div className="md:w-1/3 px-2 mb-2">
                      <p>Серия паспорта</p>
                      <input 
                        type="text" 
                        required 
                        placeholder="1234" 
                        className="w-full p-2 border border-gray-300 rounded-none" 
                        value={child.childDocumentSerial} 
                        onChange={(e) => handleChildInputChange(index, 'childDocumentSerial', e.target.value)} 
                      />
                    </div>
                    <div className="md:w-1/3 px-2 mb-2">
                      <p>Номер паспорта</p>
                      <input 
                        type="text" 
                        required 
                        placeholder="567890" 
                        className="w-full p-2 border border-gray-300 rounded-none" 
                        value={child.childDocumentNumber} 
                        onChange={(e) => handleChildInputChange(index, 'childDocumentNumber', e.target.value)} 
                      />
                    </div>
                    <div className="md:w-1/3 px-2 mb-2">
                      <p>Дата выдачи паспорта</p>
                      <input 
                        type="date" 
                        required 
                        className="w-full p-2 border border-gray-300 rounded-none" 
                        value={child.childDocumentIssueDate} 
                        onChange={(e) => handleChildInputChange(index, 'childDocumentIssueDate', e.target.value)} 
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <p>Кем выдан паспорт</p>
                    <input 
                      type="text" 
                      required 
                      placeholder="ОВД г. Москвы" 
                      className="w-full p-2 border border-gray-300 rounded-none" 
                      value={child.childDocumentIssuedBy} 
                      onChange={(e) => handleChildInputChange(index, 'childDocumentIssuedBy', e.target.value)} 
                    />
                  </div>
                  <div className="mb-4">
                    <p>Код подразделения</p>
                    <input 
                      type="text" 
                      required 
                      placeholder="123-456" 
                      className="w-full p-2 border border-gray-300 rounded-none" 
                      value={child.childDocumentDepartmentCode} 
                      onChange={(e) => handleChildInputChange(index, 'childDocumentDepartmentCode', e.target.value)} 
                    />
                  </div>
                </>
              )}

            {child.docType === 'Свидетельство о рождении' && (
                  <>
                    <div className="md:flex md:flex-wrap -mx-2">
                      <div className="md:w-1/3 px-2 mb-2">
                        <p>Серия свидетельства о рождении</p>
                        <input 
                          type="text" 
                          required 
                          placeholder="I-OP 123456" 
                          className="w-full p-2 border border-gray-300 rounded-none" 
                          value={child.childDocumentSerial} 
                          onChange={(e) => handleChildInputChange(index, 'childDocumentSerial', e.target.value)} 
                        />
                      </div>
                      <div className="md:w-1/3 px-2 mb-2">
                        <p>Номер свидетельства о рождении</p>
                        <input 
                          type="text" 
                          required 
                          placeholder="123456" 
                          className="w-full p-2 border border-gray-300 rounded-none" 
                          value={child.childDocumentNumber} 
                          onChange={(e) => handleChildInputChange(index, 'childDocumentNumber', e.target.value)} 
                        />
                      </div>
                      <div className="md:w-1/3 px-2 mb-2">
                        <p>Дата выдачи свидетельства</p>
                        <input 
                          type="date" 
                          required 
                          className="w-full p-2 border border-gray-300 rounded-none" 
                          value={child.childDocumentIssueDate} 
                          onChange={(e) => handleChildInputChange(index, 'childDocumentIssueDate', e.target.value)} 
                        />
                      </div>
                    </div>
                  </>
                )}

              <div className="mb-4">
                <p>Дополнительная информация (необязательно)</p>
                <textarea 
                  placeholder="Дополнительные сведения" 
                  className="w-full p-2 border border-gray-300 rounded-none h-32"
                  value={child.childAdditionalInfo}
                  onChange={(e) => handleChildInputChange(index, 'childAdditionalInfo', e.target.value)}
                ></textarea>
              </div>

              <p>Стоимость путевки: {child.campCost} рублей</p>
          
          {index === children.length - 1 && (
            <>
              <button type="button" onClick={addChild} className="text-blue-500 mr-2">
                <span className="text-lg">+</span>
              </button>
              {children.length > 1 && (
                <button type="button" onClick={() => removeChild(index)} className="text-red-500">
                  <span className="text-lg">−</span>
                </button>
              )}
            </>
          )}

            </div>
            
          ))}

          
  
          <div className='md:flex items-center justify-between'>
            <div className='font-bold text-xl'>
              Итого: {getTotalCost()}₽
            </div>
            <div className='text-end'>
              <div className=''>
              <button
                type="submit"
                className={`md:w-auto w-full md:mt-0 mt-2 px-8 py-2 ${isButtonDisabled ? 'bg-gray-500' : 'bg-green-500'} text-white rounded-none`}
                disabled={isButtonDisabled} // Use isButtonDisabled to control the disabled state of the button
              >
                Перейти к оплате
              </button>
              </div>
              <div className="text-xs mt-2 text-center md:text-right">
                Нажимая на кнопку, вы соглашаетесь с<br></br> <Link className='color-primary' to="/privacy">Политикой конфиденциальности</Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
  

export default PurchasePage;
