import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className='bg-blue'>
    <div className='container max-w-6xl mx-auto py-5'>
        <div className='flex text-center flex-col lg:flex-row justify-between lg:mx-0 mx-5'>
          <p className='text-white'>© 2024 Всероссийское физкультурно-спортивное общество "ДИНАМО"</p>
          <Link to={'/privacy'} className='text-white smooth-underline mt-4 lg:mt-0'>Политика конфиденциальности</Link>
        </div>
    </div>
  </footer>
);

export default Footer;
