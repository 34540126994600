import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const PaymentFailPage = () => {
  return (
    // Используем классы min-h-screen для обеспечения минимальной высоты во весь экран и flex flex-col чтобы содержимое растягивалось вертикально
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col flex-grow">
        <div className='bg-blue custom-bg w-full'>
          <div className='container max-w-6xl mx-auto md:py-16 py-10 lg:px-0 px-5'>
            <h1 className='text-white lg:text-5xl text-3xl font-bold'>Информация о заказе</h1>
          </div>
        </div>
        <div className="container mx-auto my-24 flex flex-col justify-center items-center">
          <h1 className="mt-5 text-3xl font-bold">Ошибка платежа</h1>
          <p className="mt-2 text-xl">Попробуйте снова</p>
          <div className="mt-12">
            <Link to="/" className="text-white text-xl bg-blue text-md px-24 py-3 border-2 border-blue hover:bg-white hover:border-black hover:text-black transition-colors duration-200 font-medium">
              На главную
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentFailPage;
